interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems5: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'CheckLists',
    url: '/sonder/checklists',
    icon: 'icon-star'
  },
  {
    name: 'Configuración',
    url: '/icons',
    icon: 'icon-star',
    children: [
    {
      name: 'General',
      url: '/sonder/configgeneral',
      icon: 'icon-puzzle'
    },
    {
      name: 'Vehiculos',
      url: '/sonder/configvehiculos',
      icon: 'icon-puzzle'
    }
    ]
  },
  {
    name: 'Conductores',
    url: '/sonder/conductores',
    icon: 'icon-puzzle'
  },
  {
    name: 'Propietarios',
    url: '/sonder/propietarios',
    icon: 'icon-puzzle'
  },
  {
    name: 'Clientes',
    url: '/sonder/clientes',
    icon: 'icon-puzzle'
  },
  {
    name: 'Representantes',
    url: '/sonder/representantes/0',
    icon: 'icon-puzzle'
  },
  {
    name: 'Vehiculos',
    url: '/sonder/vehiculos',
    icon: 'icon-puzzle'
  }


];
